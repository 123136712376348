<template>
    <div class="Organizingactivities">
        <!-- <Header/>  -->
        <div class="Organizingactivities_warp">
            <div class="Organizingactivities_contain">
                <h3>活动报名</h3>
                <div class="Essentialinformation">
                    <div class="label-root">
                        <label for="company">
                            <div class="label-container">
                                <span>* {{$t('ApplicationExhibition.Company')}}</span>
                                <div class="input-container">
                                    <input type="text" name="" id="company"
                                           v-model="application.company"
                                           class="input"
                                           :class="{showborder:thiscompany == true}"
                                           @blur="changecompany">
                                    <div class="after"></div>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div class="label-root">
                        <label for="Contacts">
                            <div class="label-container">
                                <span>* {{$t('ApplicationExhibition.Contacts')}}</span>
                                <div class="input-container">
                                    <input type="text" name="" id="Contacts"
                                           v-model="application.contacts"
                                           class="input"
                                           :class="{showborder:thiscontacts == true}"
                                           @blur="changecontacts">
                                    <div class="after"></div>

                                </div>
                            </div>
                        </label>
                    </div>

                    <div class="label-root">
                        <label for="Mobilephone">
                            <div class="label-container">
                                <span>{{$route.params.lang == 'zh' ? '*' : ''}} {{$t('ApplicationExhibition.Mobilephone')}}</span>
                                <div class="input-container">
                                    <input type="text" name="" id="Mobilephone"
                                           v-model="application.mobilephone"
                                           class="input"
                                           :class="{showborder:thismobilephone == true}"
                                           @blur="changemobilephone"
                                    >
                                    <div class="after"></div>

                                </div>
                            </div>
                        </label>
                    </div>
                    <div class="label-root" v-if="$route.params.lang == 'en'">
                        <label for="mailbox">
                            <div class="label-container">
                                <span>* {{$t('ApplicationExhibition.Mailbox')}}</span>
                                <div class="input-container">
                                    <input type="text" name="" id="mailbox"
                                           v-model="application.mailbox"
                                           class="input"
                                           :class="{showborder:thismailbox == true}"
                                           @blur="changemailbox">
                                    <div class="after"></div>
                                </div>
                            </div>
                        </label>
                    </div>

                    <div class="label-root">
                        <label for="VerificationCode" class="VerificationCode">
                            <div class="label-container">
                                <span>* {{$t('Newedition.VerificationCode')}}</span>
                                <div class="input-container">
                                    <input type="text" name="" id="VerificationCode"
                                           v-model="application.verificationCode"
                                           class="VerificationCode input"
                                           :class="{showborder:thisverificationCode == true}"
                                           @blur="changeverificationCode">
                                    <div class="after"></div>

                                </div>
                                <input type="button"
                                       class="btn"
                                       v-model="codeMsg"
                                       @click="Verification"
                                       :disabled="codeDisabled"/>
                            </div>
                        </label>
                    </div>

                    <div class="label-root" v-if="$route.params.lang == 'zh'">
                        <label for="mailbox">
                            <div class="label-container">
                                <span> {{$t('ApplicationExhibition.Mailbox')}}</span>
                                <div class="input-container">
                                    <input type="text" name="" id="mailbox"
                                           v-model="application.mailbox"
                                           class="input"
                                           :class="{showborder:thismailbox == true}"
                                           @blur="changemailbox">
                                    <div class="after"></div>
                                </div>
                            </div>
                        </label>
                    </div>
                </div>
                <div class="Submission" @click="Submission">
                    提交
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>
<script>
    import Header from '../../components/header' //头部
    import Footer from '../home/children/Footer' //页脚
    import {registerActivity} from '../../publicApi/registerActivity'
    import {registersendCode} from '../../publicApi/registerActivity'
    import {Toast} from 'vant';
    import md5 from "md5";

    export default {
        components: {
            Header,
            Footer
        },
        data() {
            return {
                application: {
                    company: '', //公司
                    contacts: '',//联系人
                    mobilephone: '',//手机
                    verificationCode: '',//验证码
                    mailbox: '',//邮箱
                },
                codeDisabled: false,
                countdown: 60,
                codeMsg: '获取验证码',
                timer: null,
                thiscompany: false, //公司
                thiscontacts: false, //联系人
                thismobilephone: false, //手机
                thisverificationCode: false,//验证码
                valscode: false, //点击验证码
                thismailbox: false
            }
        },
        methods: {
            //提交申请的验证
            getSubmission() {
                let reg = /^1[3|4|5|7|8|9][0-9]{9}$/;
                let mailbox = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                if (this.application.company === '') {
                    this.thiscompany = true
                    Toast('公司不能为空');
                } else if (this.application.contacts === '') {
                    this.thiscontacts = true
                    Toast('联系人不能为空')
                } else if (this.application.mobilephone === '' && this.$route.params.lang === 'zh') {
                    this.thismobilephone = true
                    Toast('手机号码不能为空')
                } else if (this.application.mailbox === '' && this.$route.params.lang === 'en') {
                    this.thismailbox = true;
                    Toast('邮箱不能为空')
                } else if (this.application.mailbox !== '' && !mailbox.test(this.application.mailbox)) {
                    this.thismailbox == true
                    Toast('你输入的邮箱不正确')
                } else if (!reg.test(this.application.mobilephone) && this.$route.params.lang === 'zh') {
                    this.thismobilephone = true
                    Toast('你的手机号码有误请重新输入')
                } else if (this.valscode == false) {
                    this.thisverificationCode = true
                    Toast('请先获取验证码')
                } else {
                    return true
                }

            },
            //提交申请
            Submission() {
                if (this.getSubmission()) {
                    this.getCurrentAplicationsubmission()
                }
            },
            //清楚表单

            //提交申请数据请求
            async getCurrentAplicationsubmission() {
                const channel = localStorage.getItem('conmart.channel')||"";
                let res = await registerActivity({
                    code: this.application.verificationCode,
                    channel: channel,
                    company: this.application.company,
                    email: this.application.mailbox,
                    lang: this.$transformAPILang(),
                    mobile: this.application.mobilephone,
                    name: this.application.contacts,
                    expo: 'km2020',
                    activityId: 0
                })
                if (res.status == 2 && res.data == null) {
                    Toast('你好！ 你的手机号 已经申请了 请勿重复提交')
                    this.Clearinputbox()
                }
                if (res.status == -1 && res.data == null) {
                    console.log('验证码格式有误')
                }
                if (res.status == 2 && res.data == false) {
                    Toast('你输入的验证码有误 请重新获取')
                }
                if (res.status == 0 && res.data == true) {
                    Toast('恭喜你 提交成功')
                    this.Clearinputbox()
                }
            },
            //获取验证码的验证
            getVerification() {
                let reg = /^1[3|4|5|7|8|9][0-9]{9}$/;
                if (this.application.mobilephone === '') {
                    Toast('手机号码不能为空')
                } else if (!reg.test(this.application.mobilephone)) {
                    Toast('你的手机号码有误请重新输入')
                } else if (!this.timer) {
                    this.timer = setInterval(() => {
                        if (this.countdown > 0 && this.countdown <= 60) {
                            this.countdown--;
                            if (this.countdown !== 0) {
                                this.codeMsg = "重新发送(" + this.countdown + ")";
                            } else {
                                clearInterval(this.timer);
                                this.codeMsg = "获取验证码";
                                this.countdown = 60;
                                this.timer = null;
                                this.codeDisabled = false;
                            }
                        }
                    }, 1000)
                }
            },
            async getCurrentApplicationesendCode() {
                const isZH = this.$route.params.lang === 'zh';
                let target = isZH ? this.application.mobilephone : this.application.mailbox;
                let n = ~~(Math.random() * 10);
                let time = Date.now();
                let code = `${n}${md5(`${n}${time}${target}`)}`;
                let res = await registersendCode({
                    code: code,
                    target: target,
                    time: time,
                    type: isZH ? 'Sms' : 'Email',
                    key: 'ActivityRegister'
                });
                if (res.status == 0 && res.data == true) {
                    Toast(isZH ? '验证码发送成功，请注意查看你手机' : this.$t('邮件发送成功，请注意查看你的邮件'));
                }
            },
            validateBtn() {
                if (!this.timer) {
                    this.timer = setInterval(() => {
                        if (this.countdown > 0 && this.countdown <= 60) {
                            this.countdown--;
                            if (this.countdown !== 0) {
                                this.codeMsg = "重新发送(" + this.countdown + ")";
                            } else {
                                clearInterval(this.timer);
                                this.codeMsg = "获取验证码";
                                this.countdown = 60;
                                this.timer = null;
                                this.codeDisabled = false;
                            }
                        }
                    }, 1000)
                }
            },//倒计时
            validataaction() {
                if (this.$route.params.lang === 'zh') {
                    let reg = /^1[3|4|5|7|8|9][0-9]{9}$/;
                    if (this.application.mobilephone === '') {
                        Toast('手机号码不能为空')
                    } else if (!reg.test(this.application.mobilephone)) {
                        Toast('你的手机号码有误请重新输入')
                    } else {
                        return true;
                        
                    }
                } else {
                    let mailbox = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                    if (this.application.mailbox === '') {
                        Toast('邮箱不得为空');
                    } else if (!mailbox.test(this.application.mailbox)) {
                        Toast(this.$t('你的邮箱有误请重新输入'));
                    } else {
                        return true;
                    }
                }
            },//手机号验证规则
            //发送验证码
            Verification() {
                if (this.validataaction()) {
                    this.validateBtn();
                    this.getCurrentApplicationesendCode()
                }
                this.valscode = true
            },
            changecompany() {
                this.thiscompany = false
            },
            changecontacts() {
                this.thiscontacts = false
            },
            changemobilephone() {
                this.thismobilephone = false
            },
            changeverificationCode() {
                this.thisverificationCode = false
            },
            changemailbox() {
                this.thismailbox = false
            },
            Clearinputbox() {
                this.application.company = '',
                    this.application.contacts = '',
                    this.application.mobilephone = '',
                    this.application.verificationCode = '',
                    this.application.mailbox = ''
            }
        }
    }
</script>

<style lang="scss" scoped>
    textarea {
        resize: none;
    }

    .Organizingactivities_warp {
        background: #F6F6F6;
        height: 864px;
        padding-top: 100px;

        .Organizingactivities_contain {
            margin: auto;
            min-height: 748px;
            background: #FFFFFF;
            padding: 43px 0 68px;
            box-sizing: border-box;

            width: 628px;

            @include sm {
                width: 100%;
            }

            h3 {
                text-align: center;
                font-weight: 600;
                color: rgba(51, 51, 51, 1);
                font-size: 20px;
            }
        }

        .Essentialinformation {
            margin: auto;
            box-sizing: border-box;
            position: relative;
            text-align: center;

            .label-root {
                margin: 0 auto;

                @include sm {
                    width: 9rem;
                }
            }

            label {
                margin-top: 10px;
                display: inline-block;

                @include sm {
                    width: 100%;
                }
            }

            .label-container {
                display: flex;
                align-items: center;

                > span {
                    width: 80px;
                    text-align: left;
                }

                .input-container {
                    position: relative;

                    @include sm {
                        width: 100%;
                    }
                }

                .input {
                    border-bottom: 1px solid #D8D8D8;
                    width: 320px;
                    font-size: 14px;
                    padding: 2px;
                    height: 44px;
                    position: relative;

                    & + .after {
                        content: '';
                        position: absolute;
                        display: block;
                        z-index: 1;
                        width: 100%;
                        bottom: 0;
                        height: 1px;
                        background: darken(#fed900, 5%);
                        transform: scale(0, 1);
                        transform-origin: left center;
                        transition: .3s linear;
                        opacity: 0;
                    }

                    &:focus + .after {
                        opacity: 1;
                        transform: scale(1, 1);
                    }

                    @include md {
                        width: 320px;
                    }
                    @include sm {
                        width: 100%;
                    }
                }
            }

            select {
                border-bottom: 1px solid #D8D8D8;
                width: 560px;
                font-size: 14px;
                padding: 2px;
                height: 44px;

                option {
                    margin-left: 20px;

                    padding-left: 50px;
                }
            }

            .Apply {
                width: 128px;
                height: 36px;
                background: rgba(250, 210, 20, 1);
                line-height: 32px;
                text-align: center;
                margin: 36px auto 40px;
                font-weight: 500;
                color: rgba(255, 255, 255, 1);
                cursor: pointer;

            }

            .Simple {
                margin-top: 80px;
                width: 320px;
                height: 203px;
                border: 1px solid #D8D8D8;
            }
        }

    }

    .Submission {
        width: 124px;
        height: 36px;
        background: #FAD214;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 36px;
        text-align: center;
        margin: 40px auto 20px;
        cursor: pointer;
    }

    .VerificationCode {
        position: relative;

        .btn {
            position: absolute;
            right: 9px;
            top: 5px;
            width: 88px;
            height: 28px;
            border-radius: 6px;
            line-height: 28px;
            text-align: center;
            font-size: 12px;
            font-weight: 400;
            color: rgba(255, 255, 255, 1);
            background: #FAD214;
            outline: none;
            z-index: 10;
            cursor: pointer;
        }
    }

    .showborder {
        border-bottom: 1px solid red !important;
        z-index: 1;
    }

    @include sm {
        .Organizingactivities_warp {
            padding: 0;
        }

        .Submission {
            margin-top: 20px;
        }

        .Organizingactivities_warp .Essentialinformation .Simple {
            width: 100%;
            margin-top: px2rem(34);
        }
    }
</style>