import axios from "@/utils/axios"


//活动报名
export function registerActivity({
    channel,
    code,
    company,
    email,
    expo,
    lang,
    mobile,
    name,
    activityId
}) {
    return axios({
        url: 'api/public/expoRegister/registerActivity',
        method: 'post',
        data: {
            channel,
            code,
            company,
            email,
            expo,
            lang,
            mobile,
            name,
            activityId
        }
    })
}
//获取验证码
export function registersendCode({
    code,
    target,
    time,
    type,
    key
}) {
    return axios({
        url: '/api/public/expoRegister/sendCode',
        method: 'post',
        data: {
            code,
            target,
            time,
            type,
            key

        }
    })
}